import { fromUnixTime } from "date-fns";
import isNumber from "lodash/isNumber";

import { daysOfWeekLong } from "ui/lib/constants";

export const isDev = process.env.NEXT_PUBLIC_ENVIRONMENT !== "production";
export const isLocal = process.env.NEXT_PUBLIC_ENVIRONMENT === "local";

export const getCurrentDayOfWeekIndex = () => {
  const currentDay = new Date().toLocaleString("default", { weekday: "long" });
  return daysOfWeekLong.indexOf(currentDay);
};

function dateHasTimeZone(date) {
  try {
    return date.endsWith("Z") || date.includes("+");
  } catch (e) {
    console.error(e, date);
  }
}

export function timeToUtc(date) {
  try {
    return new Date(date + (dateHasTimeZone(date) ? "" : "Z"));
  } catch (e) {
    console.error(e, date);
  }
}

export function getValidDate(date) {
  if (!date) return;
  if (isNumber(date)) return fromUnixTime(Number(date));
  return typeof date === "string" ? timeToUtc(date) : date;
}

export const limit = (array, limitNumber) => {
  if (!array || !array?.length) return array;
  if (typeof limitNumber !== "number") return array;

  return array.filter((_, i) => {
    if (i <= limitNumber - 1) return true;
  });
};

export function createDictionary(data, key = "id") {
  if (!data?.length) return {};

  const dictionary = {};
  data.map((n) => {
    dictionary[n[key]] = n;
  });

  return dictionary;
}

export const pluralize = (count, word, suffix = "s") => {
  if (count === 1) return word;
  return word + suffix;
};

export function iOS() {
  return (
    [
      "iPad Simulator",
      "iPhone Simulator",
      "iPod Simulator",
      "iPad",
      "iPhone",
      "iPod",
    ].includes(navigator?.platform) ||
    // iPad on iOS 13 detection
    (navigator?.userAgent?.includes?.("Mac") && "ontouchend" in document)
  );
}

export function isAuthenticIOS() {
  if (typeof navigator === "undefined") return false;
  return (
    ["iPad", "iPhone", "iPod"].includes(navigator?.platform) ||
    // iPad on iOS 13 detection
    (navigator?.userAgent?.includes?.("Mac") && "ontouchend" in document)
  );
}

export function validURL(str) {
  var res = str.match(
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g
  );
  return res !== null;
}

export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export function sleepOld(milliseconds) {
  let timeStart = new Date().getTime();
  // eslint-disable-next-line no-constant-condition
  while (true) {
    let elapsedTime = new Date().getTime() - timeStart;
    if (elapsedTime > milliseconds) {
      break;
    }
  }
}
