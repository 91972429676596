import * as segmentSnippet from "@segment/snippet";

export const renderSegmentSnippet = (
  apiKey = process.env.NEXT_PUBLIC_LADDER_QUIZ_SEGMENT_WRITE_KEY
) => {
  const opts = {
    apiKey,
    // note: the page option only covers SSR tracking.
    // Page.js is used to track other events using `window.analytics.page()`
    page: true,
  };

  if (process.env.NEXT_PUBLIC_ENVIRONMENT !== "production") {
    return segmentSnippet.max(opts);
  }

  return segmentSnippet.min(opts);
};
