import Head from "next/head";
import { isDev } from "../helpers/misc";

export const favIconDevWrapper = (value) => {
  return `/fav/${isDev ? "dev" : "prod"}/${value}`;
};

export const HeadContent = ({
  title = "Ladder",
  description = "Get stronger &amp; workout more. Free 7 day trial, no credit card needed!",
  image = "joinladder-og-image-3.png",
  ogImageContent = "",
}) => {
  return (
    <Head>
      <link
        rel="icon"
        type="image/x-icon"
        key={`icon/image/x-icon`}
        href={favIconDevWrapper("favicon.ico")}
      />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        key={`apple-touch-icon/180x180`}
        href={favIconDevWrapper("apple-touch-icon.png")}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        key={`image/png/32x32`}
        href={favIconDevWrapper("favicon-32x32.png")}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        key={`image/png/16x16`}
        href={favIconDevWrapper("favicon-16x16.png")}
      />
      {/* <link key="manifest" rel="manifest" href="/site.webmanifest" /> */}

      <link key="prefetch1" rel="prefetch" href="/assets/logo/white.png" />
      <link
        key="prefetch2"
        rel="prefetch"
        href="https://ladderteams.imgix.net/website/hero/gym-background.png?w=1434"
      />
      <link key="prefetch3" rel="prefetch" href="/assets/award-thing.svg" />
      <title key="title">{title}</title>
      <meta content={title} property="og:title" key="og:title" />
      <meta content={title} property="twitter:title" key="twitter:title" />
      <meta content={description} name="description" key="description" />
      <meta
        content={description}
        property="og:description"
        key="og:description"
      />
      <meta
        content={description}
        property="twitter:description"
        key="twitter:description"
      />
      <meta
        content={
          ogImageContent || "https://ladderteams.imgix.net/website/" + image
        }
        property="og:image"
        key="og:image"
      />
      <meta
        content={
          ogImageContent || "https://ladderteams.imgix.net/website/" + image
        }
        property="twitter:image"
        key="twitter:image"
      />
      <meta
        content={"@joinladder"}
        property="twitter:site"
        key="twitter:site"
      />
      <meta content="summary_large_image" name="twitter:card" />
      <meta property="og:type" content="website" />
    </Head>
  );
};
