"use client";

import Link from "next/link";
import React from "react";
import { isDev } from "ui/lib/helpers";
import { links as sitemapLinks } from "ui/lib/sitemap-links"; // renamed to avoid conflict with local variable
import { cn } from "ui/lib/utils";
import { Action, LinkAction } from "../action";
import { Logo } from "../nav";

// Define types for props
interface LinkItem {
  id: string;
  title: string;
  href: string;
  event?: {
    action: string;
    category?: string;
  };
  rel?: string;
  target?: string;
  pending?: boolean;
  dev?: boolean;
}
interface NavReplicaProps {
  linksToFilter: string[];
}
const NavReplica: React.FC<NavReplicaProps> = React.memo(({
  linksToFilter
}) => {
  const links: LinkItem[] = [{
    id: "WWOD",
    title: "Free Workout Of The Day",
    href: "/workout/today",
    event: {
      action: "WWOD_page__clicked",
      category: "footer"
    }
  }, {
    id: "pricing",
    title: "Pricing",
    href: "/pricing",
    event: {
      action: "pricing_page__clicked",
      category: "footer"
    }
  }, {
    id: "ladder_shop",
    title: "Ladder Shop",
    href: "https://shop.joinladder.com",
    event: {
      action: "ladder_shop_clicked",
      category: "footer"
    },
    rel: "noreferrer",
    target: "_blank"
  }];
  const filteredLinks = links.filter(({
    id
  }) => !linksToFilter.includes(id));
  return <div style={{
    borderColor: "#6D6D6D"
  }} className="flex items-center justify-between py-2 mb-4 space-x-4 border-b border-white lg:mb-8">
        <Logo />
        <div className="hidden lg:block">
          <div className="flex items-center space-x-6 text-sm ">
            {filteredLinks.map(({
          id,
          title,
          href,
          event,
          ...res
        }) => {
          return <LinkAction key={id} href={href} className="uppercase hover:opacity-80" event={event} {...res}>
                  {title}
                </LinkAction>;
        })}
            <Action at="home.hero.cta" event={{
          action: "find_my_plan_cta_clicked"
        }} className="hidden px-6 py-3 text-black rounded-full md:text-sm cta lg:flex bg-primary" />
          </div>
        </div>
      </div>;
});
const StayConnected: React.FC = () => {
  return <div data-sentry-component="StayConnected" data-sentry-source-file="index.tsx">
      <h4 className="font-bold">LETS STAY CONNECTED</h4>
      <span className="block mb-4 text-sm">
        Subscribe to stay tuned for latest updates. Let&apos;s do it!
      </span>
      <div className="flex w-full space-x-4">
        <input className="flex-1 px-2 py-2 lg:px-3 lg:py-4 " placeholder="Enter your email address" style={{
        background: "#212121"
      }} />
        <button aria-label={"subscribe"} className="px-4 py-2 text-sm border border-white lg:px-6 lg:py-4 md:text-base cta">
          Subscribe
        </button>
      </div>
    </div>;
};
interface SocialItemProps {
  urlPrefix?: string;
  urlText?: string;
  url: string;
  imageName: string;
  invert?: boolean;
  showName?: boolean;
}
export const SocialItem: React.FC<SocialItemProps> = ({
  urlPrefix = "https://",
  urlText = "",
  url = "",
  imageName = "",
  invert = false,
  showName = true
}) => <a className="flex space-x-3 center" target="_blank" rel="noreferrer" href={`${urlPrefix}${url}`} data-sentry-component="SocialItem" data-sentry-source-file="index.tsx">
    <img alt={imageName} className="block w-6 h-6 text-foreground" style={invert ? {
    filter: "invert(100%)"
  } : {}} src={`https://ladderteams.imgix.net/website/icons/social/${imageName}.svg`} />
    {showName ? <span className="hidden lg:flex">{urlText || url}</span> : null}
  </a>;
const QRCode: React.FC = () => <div className="hidden px-6 py-4 space-x-6 border border-white lg:flex rounded-xl" data-sentry-component="QRCode" data-sentry-source-file="index.tsx">
    <img alt="qr code" className="w-28 h-28" src="/assets/footer-qr.png" />
    <div className="center">
      <img alt="download on the app store" src="/assets/download-on-appstore.svg" />
    </div>
  </div>;
interface FooterProps {
  linksToFilter?: string[];
}
export const Footer: React.FC<FooterProps> = React.memo(({
  linksToFilter = []
}) => {
  const filteredLinks = React.useMemo(() => {
    return sitemapLinks.map(({
      items,
      ...res
    }, i) => ({
      ...res,
      items: items.filter(({
        id,
        dev
      }) => {
        if (!isDev && dev) return false;
        return !linksToFilter.includes(id);
      })
    }));
  }, [linksToFilter]);
  return <div className="px-4 pt-16 pb-16 mx-auto text-foreground lg:px-12 xl:px-24">
        <NavReplica linksToFilter={linksToFilter} />
        <div className="flex flex-col justify-between mb-4 lg:mb-8 lg:space-x-16 xl:space-x-24 lg:flex-row">
          <div>
            <QRCode />
          </div>
          <div className="flex flex-col flex-1 max-w-xl mb-12 lg:space-x-6 md:flex-row lg:mb-0">
            {/* <StayConnected /> */}
            {filteredLinks.map(({
          title,
          items
        }) => {
          return <div className="w-full mb-12" key={title}>
                  <h2 className="mb-4 text-lg leading-8 text-foreground lg:leading-tight md:mb-8 lg:text-xl heading">
                    {title}
                  </h2>
                  <ul className="flex flex-col flex-wrap space-y-4">
                    {items.map(({
                title,
                href,
                pending,
                dev,
                ...props
              }, i) => {
                return <li key={i}>
                          <Link href={href} {...props}>
                            <span className={cn("opacity-90 hover:opacity-100", {
                      "opacity-80": pending
                    })}>
                              {title}
                            </span>
                            {pending ? <span className="px-1 mx-2 text-xs font-medium text-black rounded-full bg-primary">
                                Coming soon
                              </span> : null}
                          </Link>
                        </li>;
              })}
                  </ul>
                </div>;
        })}
          </div>
          {!linksToFilter.includes("social") ? <div className="flex flex-row items-start space-x-6 lg:space-y-6 lg:space-x-0 lg:flex-col">
              <SocialItem {...{
          url: "tiktok.com/@joinladder",
          imageName: "tiktok",
          invert: true
        }} />
              <SocialItem {...{
          url: "instagram.com/joinladder",
          imageName: "instagram"
        }} />
              <SocialItem {...{
          url: "facebook.com/joinladder",
          imageName: "facebook"
        }} />
              <SocialItem {...{
          url: "support@joinladder.com",
          urlPrefix: "mailto:",
          imageName: "mail"
        }} />
            </div> : null}
        </div>
        <div className="flex space-x-2">
          <span>© {new Date().getFullYear()} Ladder Technologies, Inc.</span>
          <span>|</span>
          <a href="https://joinladder.com/terms" target="_blank" rel="noreferrer">
            Terms
          </a>
          <span>•</span>
          <a href="https://joinladder.com/privacy" target="_blank" rel="noreferrer">
            Privacy
          </a>
          <span>•</span>
          <Link href="/sitemap">Sitemap</Link>
        </div>
      </div>;
});