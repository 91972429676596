export interface Item {
  id?: string;
  title: string;
  href: string;
  target?: string;
  rel?: string;
  dev?: boolean;
  pending?: boolean;
}

// Define the SitemapLink type
export interface SitemapLink {
  id: string;
  title: string;
  items: Item[];
}

export const links: SitemapLink[] = [
  {
    id: "pages",
    title: "Pages",
    items: [
      { id: "home", title: "Home", href: "/" },
      {
        id: "pricing",
        title: "Pricing",
        href: "/pricing",
      },
      {
        id: "WWOD",
        title: "Free Workout Of The Day",
        href: "/workout/today",
      },
      {
        id: "ladder_shop",
        title: "Ladder Shop",
        href: "https://shop.joinladder.com",
        target: "_blank",
        rel: "noreferrer",
      },
      {
        id: "plan",
        title: "Find Your Plan",
        href: "https://www.joinladder.com/quiz?variant=riddler_hybrid_og_v2&page=0",
        target: "_blank",
        rel: "noreferrer",
      },
      {
        id: "blog",
        title: "Blog",
        href: "https://blog.joinladder.com",
        target: "_blank",
        rel: "noreferrer",
      },
      {
        id: "careers",
        title: "Careers",
        href: "https://ladder.breezy.hr",
        target: "_blank",
        rel: "noreferrer",
      },
    ],
  },
  {
    id: "teams",
    title: "Programs",
    items: [
      {
        title: "Ascend",
        href: "https://www.joinladder.com/team/ascend",
        target: "_blank",
        rel: "noreferrer",
      },
      {
        title: "Align",
        href: "https://www.joinladder.com/team/align",
        target: "_blank",
        rel: "noreferrer",
      },
      {
        title: "Body & Bell",
        href: "https://www.joinladder.com/team/body-and-bell",
        target: "_blank",
        rel: "noreferrer",
      },
      {
        title: "Crews Control",
        href: "https://www.joinladder.com/team/crews-control",
        target: "_blank",
        rel: "noreferrer",
      },
      {
        title: "Define",
        href: "https://joinladder.com/team/define",
        target: "_blank",
        rel: "noreferrer",
      },
      {
        title: "Elevate",
        href: "https://www.joinladder.com/team/elevate",
        target: "_blank",
        rel: "noreferrer",
      },
      {
        title: "Evolve",
        href: "https://www.joinladder.com/team/evolve",
        target: "_blank",
        rel: "noreferrer",
      },
      {
        title: "Forged",
        href: "https://www.joinladder.com/team/forged",
        target: "_blank",
        rel: "noreferrer",
      },
      {
        title: "Formation",
        href: "https://www.joinladder.com/team/formation",
        target: "_blank",
        rel: "noreferrer",
      },
      {
        title: "Iconic",
        href: "https://www.joinladder.com/team/iconic",
        target: "_blank",
        rel: "noreferrer",
      },
      {
        title: "Limitless",
        href: "https://www.joinladder.com/team/limitless",
        target: "_blank",
        rel: "noreferrer",
      },
      {
        title: "Maximus",
        href: "https://www.joinladder.com/team/maximus",
        target: "_blank",
        rel: "noreferrer",
      },
      {
        title: "Movewell",
        href: "https://www.joinladder.com/team/movewell",
        target: "_blank",
        rel: "noreferrer",
      },
      {
        title: "Project Alpha",
        href: "https://www.joinladder.com/team/project-alpha",
        target: "_blank",
        rel: "noreferrer",
      },
      {
        title: "Resilient",
        href: "https://www.joinladder.com/team/resilient",
        target: "_blank",
        rel: "noreferrer",
      },
      {
        title: "Transform",
        href: "https://www.joinladder.com/team/transform",
        target: "_blank",
        rel: "noreferrer",
      },
      {
        title: "Vitality",
        href: "https://www.joinladder.com/team/vitality",
        target: "_blank",
        rel: "noreferrer",
      },
    ],
  },
];
